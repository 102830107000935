import Dispatcher from '../dispatcher'
import {EventEmitter2} from 'eventemitter2'
import assign from 'object-assign'
import constants from '../constants'

let localGet, localSet
localGet = require('../utils/localStorage').getItem
localSet = require('../utils/localStorage').setItem

const Store = assign({}, EventEmitter2.prototype, {
  currentLandingIdAW21: undefined,
  currentLandingIdAW21Drop: undefined,
  livestreamIsOpen: true,
  menuIsBlack: false,
  liveURL: undefined,
  customer: undefined,
  getToken: () => {
    const customer = localGet(constants.CUSTOMER)
    if (customer && customer.token) return customer.token
  },
  getLivestream: () => {
    const customer = localGet(constants.CUSTOMER)
    if (customer && customer.token) {
      const myHeaders = new Headers()
      myHeaders.append("Authorization", `Bearer ${customer.token}`)
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      }
      return fetch(`${constants.API_URL}presentations/1`, requestOptions)
    } else {
      return Promise.resolve({})
    }
  },
  isCustomer: () => {
    const customer = localGet(constants.CUSTOMER)
    if (customer && customer.token) {
      const myHeaders = new Headers()
      myHeaders.append("Authorization", `Bearer ${customer.token}`)
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      }
      return fetch(`${constants.API_URL}me`, requestOptions)
    } else {
      return Promise.resolve({})
    }
  },
  dispatchToken: Dispatcher.register((payload) => {
    const actionType = payload.type
    const item = payload.item
    switch (actionType) {
      case constants.APP_START:
        Store.emit(actionType, item)
        break
      case constants.OPEN_LIVESTREAM:
        Store.livestreamIsOpen = true
        Store.emit(actionType)
        break
      case constants.CLOSE_LIVESTREAM:
        Store.livestreamIsOpen = false
        Store.emit(actionType)
        break
      case constants.UPDATE_LIVESTREAM_URL:
        Store.liveURL = item
        Store.emit(actionType)
        break
      case constants.CHANGE_COLLECTION_AW21:
        Store.currentLandingIdAW21 = item.id
        Store.emit(actionType, item)
        break
      case constants.CHANGE_COLLECTION_AW21_DROP:
        Store.currentLandingIdAW21Drop = item.id
        Store.emit(actionType, item)
        break
      case constants.MENU_TO_BLACK:
        Store.menuIsBlack = item
        Store.emit(actionType, item)
        break
      case constants.LOGIN:
        localSet(constants.CUSTOMER, {})
        fetch(`${constants.API_URL}auth/login`, assign(constants.POST_OPTIONS, {body: JSON.stringify(item)}))
          .then((res) => res.json())
          .then((data) => {
            if (data.access_token) {
              const tkn = {token: data.access_token}
              localSet(constants.CUSTOMER, tkn)
              Store.emit(actionType, tkn)
            } else {
              localSet(constants.CUSTOMER, {})
              Store.emit(actionType, {statusCode: 401})
            }
          })
          .catch(() => {
            Store.emit(actionType, {statusCode: 401})
          })
        break
      case constants.SSOLOGIN:
        localSet(constants.CUSTOMER, {})
        fetch(`${constants.API_URL}auth/login-sso`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${item}`
          }
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.access_token) {
              const tkn = {token: data.access_token}
              localSet(constants.CUSTOMER, tkn)
              Store.emit(actionType, tkn)
            } else {
              localSet(constants.CUSTOMER, {})
              Store.emit(actionType, {statusCode: 401})
            }
          })
          .catch(() => {
            Store.emit(actionType, {statusCode: 401})
          })
        break
      default:
        Store.emit(actionType, item)
        break
    }
  })
})

export default Store
