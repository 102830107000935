import Flux from 'flux'
import assign from 'object-assign'

const Dispatcher = assign(new Flux.Dispatcher(), {
  /**
   * For App Store
   * @param  {[object]} action
   */
  handleAppAction: (action) => {
    Dispatcher.dispatch(action)
  }
})

export default Dispatcher
