import constants from '../constants'
import Dispatcher from '../dispatcher'

const Actions = {
  appStart: () => {
    Dispatcher.handleAppAction({
      type: constants.APP_START,
      item: undefined
    })
  },
  openLivestream: () => {
    Dispatcher.handleAppAction({
      type: constants.OPEN_LIVESTREAM,
      item: undefined
    })
  },
  closeLivestream: () => {
    Dispatcher.handleAppAction({
      type: constants.CLOSE_LIVESTREAM,
      item: undefined
    })
  },
  changeCollectionAw21: (id) => {
    Dispatcher.handleAppAction({
      type: constants.CHANGE_COLLECTION_AW21,
      item: id
    })
  },
  changeCollectionAw21Drop: (id) => {
    Dispatcher.handleAppAction({
      type: constants.CHANGE_COLLECTION_AW21_DROP,
      item: id
    })
  },
  login: (user) => {
    Dispatcher.handleAppAction({
      type: constants.LOGIN,
      item: user
    })
  },
  ssoLogin: (idToken) => {
    Dispatcher.handleAppAction({
      type: constants.SSOLOGIN,
      item: idToken
    })
  },
  updateLivestreamURL: (url) => {
    Dispatcher.handleAppAction({
      type: constants.UPDATE_LIVESTREAM_URL,
      item: url
    })
  },
  setMenuToBlack: (val) => {
    Dispatcher.handleAppAction({
      type: constants.MENU_TO_BLACK,
      item: val
    })
  },
}

export default Actions
